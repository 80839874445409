import { template as template_4c5b9c12fbb349a08d1be4a4f7204cf1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4c5b9c12fbb349a08d1be4a4f7204cf1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
